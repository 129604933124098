// @import 'breakpoint';
@import '~breakpoint-sass/stylesheets/_breakpoint.scss';
@import './fonts/style.css';

$bistre: #251605;
$carmine-m-p: #d62246;
$hunter-green: #4b644a;
$bright-yellow-crayola: #fcab10;
$pacific-blue: #50b2c0;

@keyframes glowing-text {
  0% {
    color: #f2f2f2;
    text-shadow: 0 0 1px $carmine-m-p, 0 0 1px #ffffff;
  }
  100% {
    color: #d5d5d5;
    text-shadow: 0 0 4px $carmine-m-p, 0 0 9px #ffffff;
  }
}
@keyframes glowing-bg {
  0% {
    background-color: #33333333;
  }
  100% {
    background-color: rgba($carmine-m-p, 0.2);
  }
}

.mainTitle {
  margin-bottom: 0;

  &__wrapper {
    position: fixed;
    right: 1rem;
    top: 40vh;
    width: 100%;
    text-align: center;
    mix-blend-mode: exclusion;
    text-shadow: 0px 1px 13px #333, 0px 1px 13px #333, 0px 1px 1px #fff, 0px 1px 19px #fff;
    transform: translateY(-50%);

    @include breakpoint(670px) {
      top: 50vh;
      width: initial;
    }
  }
}

.subTitle {
  font-size: .6rem;
  margin-top: 0;
}

.spokpojken {
  background-size: cover;
  background-position: 100% 0;
  background-color:  darken($hunter-green, 20%);
  color: #f2f2f2;
  height: 100vh;
  max-height: stretch;
  min-width: 100vw;
  margin: 0;
  backface-visibility: hidden;
  position: relative;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  &.avif {
    background-image: url('../public/spokpojken_bg.avif');
  }
  &.webp {
    background-image: url('../public/spokpojken_bg.webp');
  }
}



.linkList {
  padding: 0;
  margin: 0;
  width: 100vw;
  height: 50vh;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: stretch;

  @include breakpoint(670px) {
    width: 50vw;
    height: 100vh;
    position: relative;
    bottom: initial;
  }
  .icon {
    margin-right: 5px;
  }
}

a {
  color: #f2f2f2;
  transition: color 0.2s;
  &:hover, &:focus {
    color: $bright-yellow-crayola;
  }
  &:active {
    color: lighten($bright-yellow-crayola, 20%);
  }
}

$shadow: darken(rgba($carmine-m-p, 0.9), 10%);

.link {
  flex: 1 1 0;
  display: inline-flex;
  gap: 0.5rem;
  padding: 0;

  a {
    text-decoration: none;
    background-color: #33333333;
    transition: box-shadow 0.4s, text-shadow 0.4s, transform 2s ease-in-out,
      background-color 2s, border-color 0.2s;
    animation: glowing-text 3s infinite alternate-reverse,
      glowing-bg 3s infinite alternate-reverse ease-in-out;
    border-top: 1px solid rgba($carmine-m-p, 0.3);
    padding: 0 2rem;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;

    &:hover, &:focus {
      animation: none;
      transition: box-shadow 52s 0.2s ease-in-out, text-shadow 52s ease-in-out,
        transform 0.2s, background-color 0.2s, border-color 0.2s, transform 0.2s;
      background-color: rgba($carmine-m-p, 0.9);
      box-shadow: 50vw 0 20vw $shadow;
      border-color: darken($carmine-m-p, 10%);
    }
    @include breakpoint(670px) {
      box-shadow: 0 0 0 $shadow;
      animation: glowing-text 3s infinite alternate-reverse;
      border-right: 1px solid rgba($carmine-m-p, 0.3);
      border-width: 1px;
      text-shadow: 1px 0 1px $bright-yellow-crayola;

      &:hover, &:focus {
        a {
          transform: translateX(5px);
        }
        text-shadow: 1px 0 1px $bright-yellow-crayola,
          2px 0 2px $bright-yellow-crayola, 3px 0 3px $bright-yellow-crayola,
          6px 0 6px $bright-yellow-crayola, 7px 0 7px $bright-yellow-crayola,
          7px 0 7px $bright-yellow-crayola, 8px 0 8px $bright-yellow-crayola,
          10px 0 10px $bright-yellow-crayola, 9px 0 9px $bright-yellow-crayola;
        color: lighten($bright-yellow-crayola, 10%);
      }

      &:first-child {
        border-top: 0;
      }
    }
  }

}
